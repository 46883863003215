import React from 'react'
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";

const TextEditor = () => {
    return (
        <>
            <AceEditor
                placeholder="Write something !!!"
                mode="python"
                theme="monokai"
                name="editor"
                value={`while success != True:
    tryAgain()
    print('Find & Fix the problem')
    continue

if success == True:
    aimHigherNow()
    print('Congrats, keep moving forward')`}
                fontSize={20}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                width='full'
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 2,
                }}
                style={{
                    height: '100%'
                }}
            />
        </>
    )
}

export default TextEditor